.navbar{
  display: flex;
  justify-content: space-around;
  padding: 16px;
  box-shadow: 0 1px 3px -2px black;
  align-items: center;
  /* background-color: #003e1a; */
  background: linear-gradient(90deg, #003e1a 0%, #006837 50%, #003e1a 100%);

  z-index: 2;
  }

.nav-logo{
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-logo img{
 height: 5rem;
 /* height: auto; */
 border-radius: 20px;
}
.nav-medal img{
 /* width: 6rem; */
 height: 5rem;
}

.whatsapp-button {
  font-weight: 600;
  background-color: #25d366; 
  color: #fff; 
  border: none; 
  padding: 10px; 
  margin: 0px; 
  border-radius: 50%;
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  font-size: 0px;
  height: 5rem;
  width: 5rem;
}

.whatsapp-button:hover {
  background-color: #128c7e; 
}

.whatsapp-button a {
  padding: 0px;
  margin: 0px;
}

@media(max-width:1280px){
  .navbar{
    padding: 12px 50px;
  }
  .nav-logo img, .nav-medal img{
    width: 5rem;
  }
  /* .nav-logo p{
    font-size: 25px;
  } */

}
@media(max-width:1024px){
  .navbar{
    padding: 12px 30px;
  }
}
@media(max-width:800px){
  .navbar{
    padding: 10px 0px;
  }
}

@media(max-width:500px){
  .navbar{
    padding: 8px 0px;
    gap:0px;
  }
  /* .nav-medal{
    transform: scale(0.8);
  } */

  .nav-logo img{
    height: 3rem;
    width: auto;
   }
   .nav-medal img{
    height: 3rem;
    width: 3rem
   }
   
   .whatsapp-button {
     height: 3rem;
     width: 3rem;
   }

   .whatsapp-button a svg{
    height: 1.6rem;
    width: 1.6rem;
   }
}