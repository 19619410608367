/* Estilizando a barra de rolagem para navegadores Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
  height: 12px; /* Altura da barra de rolagem horizontal */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da área de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #2c3d48; /* Cor do thumb (a parte que se movimenta) */
  border-radius: 6px; /* Bordas arredondadas do thumb */
  border: 3px solid #f1f1f1; /* Borda ao redor do thumb para criar um espaçamento */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1f2d36; /* Cor do thumb ao passar o mouse por cima */
}

/* Estilizando a barra de rolagem para o Firefox */
body {
  scrollbar-width: thin; /* Definindo uma barra de rolagem mais fina */
  scrollbar-color: #2c3d48 #f1f1f1; /* Cor do thumb e do track */
}

/* Estilizando a barra de rolagem para o Internet Explorer e Edge */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Barra de rolagem estilizada */
}

a{
  text-decoration: none;
  color: white
}

html {
  scroll-behavior: smooth;
}
