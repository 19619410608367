.div-section-1{
  width: 80%;
  /* border: 1px solid black; */
  margin: 0 auto;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.div-section-1 h1,.div-section-1 h2{
  text-align: center;
  z-index: 1;
  color: #252525;
}

/* .div-section-1 span{
  color: gray;
} */

.icon{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.icon img{
  width: 6rem;
}

.bar {
  height: 5px;
  width: 80%;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #2b7534;
  transition: width 0.2s ease-in-out; 
}

.icon:hover .bar::after {
  width: 100%;
}

.div-icons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px; /* Ajuste conforme necessário */
  align-items: center; /* Alinha verticalmente os itens */
}

.text-swap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px; 
  overflow: hidden;
}

.text {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color:#696969;
}

.top-text {
  transition: transform 0.35s
}

.icon:hover .top-text {
  transform: translateY(-50%);
}

@media (max-width: 768px){
  .div-icons{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  .bar{
    height: 10px;
    background-color: #2b7534;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1550px){
  .icon p{
    font-size: 20px;
  }
}

