/* Form.css */
.form-container {
  width: 80%;
  /* border: 1px solid black; */
  margin: 0 auto;
  min-height: 30rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px 30px
}

.form-text {
  flex: 1;
  min-width: 300px;
  margin-right: 20px;
}

.form-text h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.form-text p {
  font-size: 1.2em;
}

.form-fields {
  flex: 1;
  min-width: 300px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  /* margin-bottom: 20px; */
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px;
  background-color: #005234;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #006400;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

button[disabled]:hover {
  background-color: #ccc;
}

button {
  transition: background-color 0.3s;
}


/* Responsivo */
@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }

  .form-text, .form-fields {
    min-width: 100%;
    margin: 0;
  }

  .form-text {
    margin-bottom: 20px;
  }

  .form-text h1 {
    font-size: 2em;
    text-align: center;
    /* margin-bottom: 10px; */
  }
  .form-text p {
    /* font-size: 2em; */
    text-align: center;
    /* margin-bottom: 10px; */
  }
}
