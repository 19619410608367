.div-section-2{
  width: 90%;
  /* border: 1px solid black; */
  margin: 0 auto;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.container-section-2-row{
  display: flex;
  flex-direction: row;
}

.container-section-2-row-reverse{
  display: flex;
  flex-direction: row-reverse;
}

.left-section-2{
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  padding: 10px 40px;
}

.left-section-2 h1{
  font-size: 30px;
}
.left-section-2 p{
  font-size: 18px;
}

.div-alerts-section-2{
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.btn-contato-section-2{
  padding: 12px 50px;
  color: white;
  background-color: #005234;
  border-radius: 12px;
  border: 0;
  font-size: 20px;
}
.btn-contato-section-2:hover{
background-color: #25d366;
}

.right-section-2{
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-section-2 img{
  height: 15rem;
}

@media (max-width: 768px){
  .container-section-2-row-reverse, .container-section-2-row{
    flex-direction: column;
  }
  .div-alerts-section-2{
    flex-direction: column;
    gap: 1rem;
  }
  .left-section-2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 5px 0px;
  }
  .left-section-2 h1{
    text-align: center;
  }
  .left-section-2 p{
    text-align: center;
  }
  .right-section-2{
    width: 100%;
  }
  .right-section-2 img{
    height: auto;
    width: 80vw;
  }
}



