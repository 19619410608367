.div-section-2-refrigeracao{
  width: 90%;
  /* border: 1px solid black; */
  margin: 0 auto;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.container-section-2-row-refrigeracao{
  display: flex;
  flex-direction: row;
}

.container-section-2-row-reverse-refrigeracao{
  display: flex;
  flex-direction: row-reverse;
}

.left-section-2-refrigeracao{
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  padding: 10px 40px;
}

.left-section-2-refrigeracao h1{
  font-size: 30px;
  color: #003e1a;
}
.left-section-2-refrigeracao p{
  font-size: 18px;
}

.div-alerts-section-2-refrigeracao{
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.btn-contato-section-2-refrigeracao{
  padding: 12px 50px;
  color: white;
  background-color: #005234;
  border-radius: 12px;
  border: 0;
  font-size: 20px;
}
.btn-contato-section-2-refrigeracao:hover{
background-color: #25d366;
}

.right-section-2-refrigeracao{
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .right-section-2-refrigeracao img{
  height: 15rem;
  width: 100%;
} */

.text-section-refrigeracao{
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.carousel-image-container {
  position: relative;
  width: 100%;
  height: 30rem; /* Altura do container do carrossel */
  background-color: #ffffff; /* Cor de fundo para preencher o espaço vazio */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  width: auto;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px){
  .div-section-2-refrigeracao{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .container-section-2-row-reverse-refrigeracao, .container-section-2-row-refrigeracao{
    flex-direction: column;
  }
  .div-alerts-section-2-refrigeracao{
    flex-direction: column;
    gap: 1rem;
  }
  .left-section-2-refrigeracao{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 5px 0px;
  }
  .left-section-2-refrigeracao h1{
    text-align: center;
  }
  .left-section-2-refrigeracao p{
    text-align: center;
  }
  .right-section-2-refrigeracao{
    width: 100%;
  }
  .right-section-2-refrigeracao img{
    height: auto;
    width: 80vw;
  }
}
