.seguranca-container {
  display: flex;
  align-items: center;
  background-color: #f8f8f8; 
  padding: 10px 20px;
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.iconCard {
  font-size: 24px; 
  color: #6d6a6a; 
  margin-right: 10px; 
}

.text-container {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  font-size: 14px !important; 
  color: #ad0e09; 
  margin: 0; 
  font-weight: 700;
  white-space: nowrap;
}

.title .circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 12px; /* Ajuste o tamanho da fonte se necessário */
  border-radius: 50%;
  border: 2px solid #ad0e09;
  margin-right: 5px; 
  line-height: 1; /* Garante que a altura da linha seja consistente */
}

.text-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Ajuste o tamanho da fonte conforme necessário */
}

.subtitle {
  font-size: 14px; 
  color: #6d6a6a;
  margin: 0; 
}

@media (max-width: 768px){
  .title {
    font-size: 14px !important; 
    font-weight: 500;
  }
}
