.divider1{
  padding: 16px;
  box-shadow: 0 1px 3px -2px black;
  align-items: center;
  /* background-color: #003e1a; */
  background: linear-gradient(90deg, #003e1a 0%, #006837 50%, #003e1a 100%);

  }

.divider1-container{
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.divider1 img{
  width: 6rem;
}

.divider-text{
  display: flex;
  flex-direction:column;
  max-width: 42%;
}

.divider-text h1{
  color: white;
  text-align: center;
}
/* .divider-text p{
  color: white;
  font-size: 18px;
  text-align: center;
} */

.divider-text p {
  color: white;
  font-size: 16px; /* Ajuste o tamanho da fonte */
  line-height: 1.4; /* Ajuste a altura da linha para ocupar o espaço desejado */
  text-align: center;
  margin: 0 auto;
}


@media (max-width: 768px){
  .divider1-container{
    flex-direction: column;
    width: 80%;
  }
  .divider-text{
    max-width: 100%;
  }
  .divider-text p {
    font-size: 14px;
    max-width: 100%;
    line-height: 1.5;
  }
}