/* Footer.css */
.footer {
  /* background-color: #2b3a42; */
  background: linear-gradient(90deg, #003e1a 0%, #006837 50%, #003e1a 100%);

  color: white;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.footer-section1 {
  /* flex: 1; */
  display: flex;
  align-items: center;
  min-width: 200px;
  /* margin: 10px; */
  text-align: center;
}
.footer-section2 {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  /* margin: 10px; */
  text-align: center;
  gap: 5px;
}

.footer-logo .logo-placeholder {
  background-color: #00a3cf;
  color: black;
  padding: 40px;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.footer-logo img{
  width: 6rem;
  height: auto;
  border-radius: 20px;
 }

.footer-info {
  margin-left: 10px;
}
.footer-info p {
  margin: 10px 0;
}

.footer-badge img {
  max-width: 100px;
  height: auto;
}

.footer i {
  margin-right: 8px;
}

.iconTextLine{
  display: flex;
  align-items: center;
  gap: 10px
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-section1 {
    min-width: 100%;
    margin: 5px 0;
    flex-direction: column;
  }

  .footer-section2 {
    min-width: 100%;
    margin: 5px 0;
  }
}
